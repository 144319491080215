export default {
    getAvailableKnowledgeBasesNum(state) {
        return state.availableKnowledgeBasesNum;
      },
    getMaxKnowledgeBasesNum(state) {
        return state.maxKnowledgeBasesNum;
    },
    getKnowledgeBases(state) {
        return state.knowledgeBases;
    },
    /* getDefaultKnowledgeBase(state) {
    return state.defaultKnowledgeBase;
    }, */
    getActiveKnowledgeBases(state) {
        return [...state.activeKnowledgeBases/* , ...state.knowledgeBasesPreset */];
    },
    getAnalizedText(state) {
        return state.analizedText;
    },
    canCreateKnowledgeBase(state, getters, rootGetters) {
        const role =rootGetters.user.user.role
        return (
            role != 'user' &&
            getters.getMaxKnowledgeBasesNum > 0 &&
            getters.getAvailableKnowledgeBasesNum > 0
        );
    },
}