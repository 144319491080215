import { Message } from "element-ui";

import DashboardLayout from "src/pages/Layout/DashboardLayout.vue";
import DashboardLayoutAuth from "src/pages/Layout/DashboardLayoutAuth.vue";

import AuthLayout from "src/pages/Pages/AuthLayout.vue";
// GeneralViews
import NotFound from "src/pages/GeneralViews/NotFoundPage.vue";
import store from "@/store/store";
import { formatCurrency } from "../util/formatCurrency";
import router from "@/routes/router";

// Calendar
const Calendar = () =>
  import(
    /* webpackChunkName: "extra" */ "src/pages/Calendar/CalendarRoute.vue"
  );
// Charts
const Charts = () =>
  import(/* webpackChunkName: "dashboard" */ "src/pages/Charts.vue");

// Components pages
const Buttons = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Components/Buttons.vue"
  );
const GridSystem = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Components/GridSystem.vue"
  );
const Panels = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Components/Panels.vue"
  );
const SweetAlert = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Components/SweetAlert.vue"
  );
const Notifications = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Components/Notifications.vue"
  );
const Icons = () =>
  import(/* webpackChunkName: "components" */ "src/pages/Components/Icons.vue");
const Typography = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Components/Typography.vue"
  );

// Dashboard pages
const Dashboard = () =>
  import(
    /* webpackChunkName: "dashboard" */ "src/pages/Dashboard/Dashboard.vue"
  );
import Widgets from "src/pages/Widgets.vue";

// Forms pages
const RegularForms = () => import("src/pages/Forms/RegularForms.vue");
const ExtendedForms = () => import("src/pages/Forms/ExtendedForms.vue");
const ValidationForms = () => import("src/pages/Forms/ValidationForms.vue");
const Wizard = () => import("src/pages/Forms/Wizard.vue");

// Maps pages
const GoogleMaps = () =>
  import(/* webpackChunkName: "extra" */ "src/pages/Maps/GoogleMaps.vue");
const FullScreenMap = () =>
  import(/* webpackChunkName: "extra" */ "src/pages/Maps/FullScreenMap.vue");
const VectorMaps = () =>
  import(/* webpackChunkName: "extra" */ "src/pages/Maps/VectorMaps.vue");

// Pages
const User = () =>
  import(
    /* webpackChunkName: "pages" */ "src/pages/Pages/MyProfile/MyProfile.vue"
  );
const Pricing = () =>
  import(/* webpackChunkName: "pages" */ "src/pages/Pages/Pricing/Pricing.vue");
const Checkout = () =>
  import(
    /* webpackChunkName: "pages" */ "src/pages/Pages/PaymentsCheckout/PaymentsCheckout.vue"
  );

const LazySubscribeNow = () =>
  import("src/pages/Pages/SubscribeNow/SubscribeNow.vue");

const TimeLine = () =>
  import(/* webpackChunkName: "pages" */ "src/pages/Pages/TimeLinePage.vue");
const Login = () =>
  import(/* webpackChunkName: "pages" */ "src/pages/Pages/Login.vue");
const Register = () =>
  import(/* webpackChunkName: "pages" */ "src/pages/Pages/Register.vue");
const RTL = () =>
  import(/* webpackChunkName: "pages" */ "src/pages/Pages/RTLPage.vue");
const Lock = () =>
  import(/* webpackChunkName: "pages" */ "src/pages/Pages/Lock.vue");

const MissingInfoAfterSignup = () =>
  import(
    "src/pages/Pages/Auth/SocialSignupMissingInfo/SocialSignupMissingInfo.vue"
  );
// TableList pages
const RegularTables = () =>
  import(/* webpackChunkName: "tables" */ "src/pages/Tables/RegularTables.vue");
const ExtendedTables = () =>
  import(
    /* webpackChunkName: "tables" */ "src/pages/Tables/ExtendedTables.vue"
  );
const PaginatedTables = () =>
  import(
    /* webpackChunkName: "tables" */ "src/pages/Tables/PaginatedTables.vue"
  );

// Tools pages
const ToolsIndex = () => import("@/pages/GeneralViews/ToolsIndex.vue");
const ToolsView = () => import("@/pages/GeneralViews/ToolsView.vue");

const FavoriteIndex = () => import("@/pages/GeneralViews/FavoriteIndex.vue");

//My Documents pages
const MyDocument = () =>
  import(
    /* webpackChunkName: "tables" */ "src/pages/Pages/MyDocuments/MyDocument.vue"
  );

const ContentDetails = () =>
  import(
    /* webpackChunkName: "ContentDetails" */ "src/pages/Pages/MyDocuments/ContentDetails/ContentDetails.vue"
  );

//Control Panel Pages
const ControlPanel = () =>
  import(
    /* webpackChunkName: "controlPanel" */ "src/pages/Pages/ControlPanel/ControlPanel.vue"
  );

//Referral pages
const Referral = () =>
  import(/* webpackChunkName: "referral" */ "src/pages/Pages/Referral.vue");

//Workspace Panel Pages
const WorkspaceLogin = () =>
  import(
    /* webpackChunkName: "WorkspaceLogin" */ "@/pages/Pages/ControlPanel/Workspace/WorkspaceLogin.vue"
  );

const WorkspaceCreateWrapper = () =>
  import(
    /* webpackChunkName: "WorkspaceCreateWrapper" */ "@/pages/Pages/ControlPanel/Workspace/WorkspaceCreateWrapper.vue"
  );

const WorkspaceSettings = () =>
  import(
    /* webpackChunkName: "WorkspaceSettings" */ "@/pages/Pages/ControlPanel/Workspace/WorkspaceSettings/WorkspaceSettings.vue"
  );

const ResetPassword = () =>
  import(
    /* webpackChunkName: "pages" */ "src/pages/Pages/Auth/ResetPassword/ResetPassword.vue"
  );

const Api = () => import("@/pages/Pages/Api/ApiPage.vue");

const tryAsyncRetrieveBillingInfo = async (to, from, next) => {
  console.log("CIAO");
  try {
    //await store.dispatch('getBillingInfo');
    await Promise.all([
      store.dispatch("getBillingInfo"),
      store.dispatch("payments/fetchDefaultPaymentMethod"),
    ]);
    next();
  } catch (error) {
    Message({
      showClose: true,
      message: error.message,
      type: "error",
    });
  }
};

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  meta: {
    requiresAuth: true,
  },
  name: "Components",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      components: { default: Buttons },
    },
    {
      path: "grid-system",
      name: "Grid System",
      components: { default: GridSystem },
    },
    {
      path: "panels",
      name: "Panels",
      components: { default: Panels },
    },
    {
      path: "sweet-alert",
      name: "Sweet Alert",
      components: { default: SweetAlert },
    },
    {
      path: "notifications",
      name: "Notifications",
      components: { default: Notifications },
    },
    {
      path: "icons",
      name: "Icons",
      components: { default: Icons },
    },
    {
      path: "typography",
      name: "Typography",
      components: { default: Typography },
    },
  ],
};
let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/regular",
  meta: {
    requiresAuth: true,
  },
  name: "Forms",
  children: [
    {
      path: "regular",
      name: "Regular Forms",
      components: { default: RegularForms },
    },
    {
      path: "extended",
      name: "Extended Forms",
      components: { default: ExtendedForms },
    },
    {
      path: "validation",
      name: "Validation Forms",
      components: { default: ValidationForms },
    },
    {
      path: "wizard",
      name: "Wizard",
      components: { default: Wizard },
    },
  ],
};

let tablesMenu = {
  path: "/table-list",
  component: DashboardLayout,
  redirect: "/table-list/regular",
  meta: {
    requiresAuth: true,
  },
  name: "Tables",
  children: [
    {
      path: "regular",
      name: "Regular Tables",
      components: { default: RegularTables },
    },
    {
      path: "extended",
      name: "Extended Tables",
      components: { default: ExtendedTables },
    },
    {
      path: "paginated",
      name: "Paginated Tables",
      components: { default: PaginatedTables },
    },
  ],
};

let myDocumentsPages = {
  path: "/",
  component: DashboardLayout,
  name: "Dashboard",
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "/orders",
      name: "Documents",
      components: { default: MyDocument },
    },
    {
      path: "/orders/:order_detail_id",
      name: "Content Detail",
      components: { default: ContentDetails },
    },
  ],
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  name: "Maps",
  redirect: "/maps/google",
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "google",
      name: "Google Maps",
      components: { default: GoogleMaps },
    },
    {
      path: "full-screen",
      name: "Full Screen Map",
      meta: {
        hideContent: true,
        hideFooter: true,
      },
      components: { default: FullScreenMap },
    },
    {
      path: "vector-map",
      name: "Vector Map",
      components: { default: VectorMaps },
    },
  ],
};

let pagesMenu = {
  path: "/pages",
  component: DashboardLayout,
  name: "Pages",
  redirect: "/pages/user",
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "user",
      name: "User Page",
      components: { default: User },
    },
    {
      path: "timeline",
      name: "Timeline Page",
      components: { default: TimeLine },
    },
    {
      path: "rtl",
      name: "RTL Page",
      components: { default: RTL },
    },
  ],
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/register",
      name: "Register",
      beforeEnter(to, from, next) {
        if (to.query.voucher) {
          next();
        } else {
          window.location.replace("https://www.contents.ai/pricing/");
        }
      },
      component: Register,
    },
    {
      path: "/lock",
      name: "Lock",
      component: Lock,
    },
    {
      path: "/impersonate",
      name: "Impersonate",
      beforeEnter: async (to, from, next) => {
        try {
          await store.dispatch("impersonateUser", { ...to.query });
        } catch (error) {
          Message({
            showClose: true,
            message: error.message,
            type: "error",
          });
        }
      },
    },
    {
      path: "/verify-email/:id/:verify_code",
      name: "VerifyMail",
      beforeEnter: async (to, from, next) => {
        try {
          await store.dispatch("verifyMail", { ...to.params });
        } catch (error) {
          Message({
            showClose: true,
            message: error.message,
            type: "error",
          });
        }
      },
    },

    {
      path: "/reset-password",
      name: "ResetPassword",
      component: ResetPassword,
      children: [
        {
          path: `/reset-password/:token`,
        },
      ],
    },
    ,
    {
      // @todo move in auth after real registration
      path: "/user/social-login",
      component: MissingInfoAfterSignup,
      name: "Social Login infos",
    },
  ],
};

let toolsIndexPages = {
  path: "/",
  component: DashboardLayout,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "tools/:slug",
      component: ToolsView,
      beforeEnter: async (to, from, next) => {
        const slug = to.params.slug ?? null;
        const tools = store.getters.getTools; // available tools for the logged user
        const exist = slug && tools.some((tool) => tool.slug === slug);
        const isHiddeInPersonalWS = tools.some(
          (tool) => tool.slug === slug && tool.has_task_assignment,
        );
        const isPersonalWS = store.getters.selectedWorkspace.id == null;
        await store.dispatch("fetchWalletInfo");
        if (store.getters.getFreemiumStatus) {
          if (
            !store.getters.walletInfoObj?.freemium_config?.included_tools.includes(
              to.params.slug,
            )
          ) {
            store.commit("setToolLockedModal", true);
            store.commit("setShowModal", true);
          }
        }
        if (!exist || (isPersonalWS && isHiddeInPersonalWS)) {
          console.log(
            `ROUTE GUARD: The Tool ${slug} doesn\'t exist, check the tools.json file.`,
          );
          next("./dashboard");
        } else if (
          slug === "text_to_image" ||
          slug === "text_to_video" ||
          slug === "image_upscaling"
        ) {
          window.location.href = window.aiArtUrl;
        } else {
          next();
        }
      },
    },
    {
      path: "favorite",
      component: FavoriteIndex,
    },
    {
      name: "ToolsIndex",
      path: "tools",
      component: ToolsIndex,
    },
  ],
};

let myProfilePages = {
  path: "/",
  component: DashboardLayout,
  /* name: 'My Profile', */
  redirect: "/my-profile",
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "my-profile",
      name: "My Profile",
      beforeEnter: async (to, from, next) => {
        await Promise.all(
          [
            store.dispatch("getBillingInfo"),
            store.dispatch("payments/fetchDefaultPaymentMethod"),
            store.dispatch("getMyBalanceInfo"),
          ],
          next(),
        );
      },
      component: User,
    },
  ],
};

let controlPanelPages = {
  path: "/",
  component: DashboardLayout,
  name: "Control Panel",
  redirect: "/control-panel",
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "control-panel",
      name: "ControlPanel",
      components: { default: ControlPanel },
      beforeEnter: async (to, from, next) => {
        if (store.getters.getFreemiumStatus) {
          next("/dashboard");
        }
        // redirect if you have role user
        if (store.getters.getUserRole == "user") {
          next("/dashboard");
        }
        await store.dispatch("fetchWalletInfo");
        await store.dispatch("fetchUserSeats");
        next();
      },
    },
  ],
};

let WorkspacePages = {
  path: "/",
  component: DashboardLayoutAuth,
  meta: {
    requiresAuth: true,
  },
  beforeEnter: (to, from, next) => {
    if (!store.getters.getHasWorkspace) {
      next("/dashboard");
    }
    next();
  },
  children: [
    {
      path: "workspace/login",
      name: "workspace.login",
      components: { default: WorkspaceLogin },
    },
    {
      path: "workspace/create",
      name: "workspace.create",
      components: { default: WorkspaceCreateWrapper },
    },
  ],
};

let WorkspaceSettingsPages = {
  path: "/",
  component: DashboardLayout,
  beforeEnter: (to, from, next) => {
    if (!store.getters.getHasWorkspace) {
      next("/dashboard");
    }
    next();
  },
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "workspace/settings/:id?",
      name: "workspace.settings",
      components: { default: WorkspaceSettings },
      beforeEnter: (to, from, next) => {
        const id = to.params.id ?? null;
        // if not in personal ws
        if (id) {
          // redirect if you have role user
          if (store.getters.getUserRole == "user") {
            next("/dashboard");
          }
          store.dispatch("fetchWorkspaces").then(() => {
            // redirect if you can't access this workspace
            if (
              id &&
              !store.getters.getWorkspaces
                .map((ws) => ws.id)
                .includes(parseInt(id))
            ) {
              next("/dashboard");
            }
            next();
          });
        }
        next();
      },
    },
  ],
};

let pricingCheckoutPages = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/pricing",
      name: "Pricing",
      meta: {
        requiresAuth: true,
      },
      beforeEnter: async (to, from, next) => {
        try {
          await Promise.all([
            store.dispatch("pricing/fetchPricings"),
            store.dispatch("fetchWalletInfo"),
          ]);

          // Redirect to dashboard if the user is a demo user
          if (store.getters.walletInfoObj.isDemoUser) {
            next("/dashboard");
          }

          next();
        } catch (error) {
          Message({
            showClose: true,
            message: error.message,
            type: "error",
          });
          next("/dashboard");
        }
      },
      component: Pricing,
    },
    {
      path: "/payments/checkout",
      name: "PaymentsCheckout",
      meta: {
        requiresAuth: true,
      },
      beforeEnter: tryAsyncRetrieveBillingInfo,
      component: Checkout,
    },
  ],
};
let ReferralPages = {
  path: "referral",
  component: DashboardLayout,
  name: "Referral",
  redirect: "/referral",
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "/referral",
      components: { default: Referral },
    },
  ],
};

let fastCheckoutPages = {
  path: "/subscribe-now",
  component: LazySubscribeNow,
  beforeEnter: async (to, from, next) => {
    const user = store.getters.getUser;
    let promises = [];
    if (to.query.plan && to.query.plan !== "FREEMIUM") {
      promises = [
        store.dispatch("fastcheckout/fetchPlan", to.query.plan),
        store.dispatch("fastcheckout/fetchFastcheckoutData"),
      ];
    } else if (to.query.amount && to.query.curr) {
      promises = [
        //store.dispatch("fastcheckout/fetchPlan", to.query.plan),
        store.dispatch("fastcheckout/fetchFastcheckoutData"),
      ];
      store.commit("cart/addToCart", {
        type: "one-shot-package",
        value: to.query.amount * 100,
        purchaseType: "human-one-shot",
      });
      const symbol = formatCurrency(to.query.curr)[0];

      store.commit(
        "pricing/setUserCurrency",
        {
          code: to.query.curr,
          symbol: symbol,
        },
        { root: true },
      );
    } else {
      promises = [store.dispatch("fastcheckout/fetchFastcheckoutData")];
    }

    store.commit("fastcheckout/setFreeTrial", false);
    if (user) {
      promises.push(
        store.dispatch("fetchUserInfo"),
        store.dispatch("getBillingInfo"),
        store.dispatch("payments/fetchDefaultPaymentMethod"),
      );
      await Promise.all(promises);
    } else {
      await Promise.all(promises);
    }
    if (to.query.coupon) {
      await store.dispatch("payments/submitCoupon", {
        coupon: to.query.coupon,
      });
    }

    next();
  },
};

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Home",
    meta: {
      requiresAuth: true,
    },
  },
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  authPages,
  // toolsPages,
  toolsIndexPages,
  myDocumentsPages,
  myProfilePages,
  controlPanelPages,
  WorkspacePages,
  WorkspaceSettingsPages,
  ReferralPages,
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    meta: {
      requiresAuth: true,
    },
    name: "Dashboard layout",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
      },
      {
        path: "calendar",
        name: "Calendar",
        components: { default: Calendar },
      },
      {
        path: "charts",
        name: "Charts",
        components: { default: Charts },
      },
      {
        path: "widgets",
        name: "Widgets",
        components: { default: Widgets },
      },
      {
        path: "user/api",
        name: "Api Page",
        components: { default: Api },
      },
    ],
  },
  pricingCheckoutPages,
  fastCheckoutPages,
  { path: "*", component: NotFound },
];

export default routes;

function redirectWorkspaceRoutes() {
  try {
    store.dispatch("fetchUserInfo").then(() => {
      if (!store.getters.getHasWorkspace) {
        next("/dashboard");
      }
      next();
    });
  } catch (error) {
    Message({
      showClose: true,
      message: error.message,
      type: "error",
    });
    next("/dashboard");
  }
}
