import { AwsCognitoService } from "@/services/awsCognitoService";
export default {
    setShowOtpModalShowClose(state, value){
        state.showOtpModal = value;
    },

    setOtpValue(state, value) {
        state.otpValue = value;
    },

    setOtpIsValidOrNot(state, value) {
        state.isOtpValid = value;
    },

    setUserIsRegisteredOnAws(state, value) {
      state.userIsRegisteredOnAws = value;
    },

    setUserIsLoggedInAws(state, value) {
      state.userIsLoggedInAws = value;
    },

    setAwsUserData(state, value) {
      state.awsUserData = value;
    },

    setAwsCognitoService(state) {
        state.AwsCognitoService = new AwsCognitoService();
    },

    setJwtData(state, value) {
      state.jwtData = value;
    },

    setIsPasswordReset(state, value) {
      state.isPasswordReset = value;
    },

    setSocialLoginDriver(state, value) {
        state.socialLoginDriver = value;
    }

}
