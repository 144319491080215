export default {
  setChangePlanDate(state, date) {
    state.changePlanDate = date;
  },
  setBillingInfo(state, billingInfo) {
    state.billingInfo = billingInfo;
  },
  setHasBillingInfo(state, hasBillingInfo) {
    state.hasBillingInfo = hasBillingInfo;
  },
  setDiscounts(state, discounts) {
    console.log(state, discounts, "state,discounts");
    state.discounts = discounts;
  },
  setCheckoutPlan(state, plan) {
    state.checkoutPlan = plan;
  },
  setCouponCode(state, couponCode) {
    console.log(state, couponCode, "state, couponCode");
    state.couponCode = couponCode;
  },
  setDefaultPaymentMethod(state, defaultPaymentMethod) {
    state.defaultPaymentMethod = defaultPaymentMethod;
  },
  setStripeClientSecret(state, stripeClientSecret) {
    state.stripeClientSecret = stripeClientSecret;
  },
  setStripeElements(state, stripeElements) {
    state.stripeElements = stripeElements;
  },
  setPaymentElement(state, paymentElement) {
    state.paymentElement = paymentElement;
  },
  setPaymentForm(state, paymentForm) {
    state.paymentForm = paymentForm;
  },
  setFormIsValid(state, formIsValid) {
    state.formIsValid = formIsValid;
  },
  setIsPaymentMethodChanged(state, isPaymentMethodChanged) {
    state.isPaymentMethodChanged = isPaymentMethodChanged;
  },
  setGtmUserInfo(state, gtmUserInfo) {
    state.gtmUserInfo = gtmUserInfo;
  },
  setCancelReasons(state, cancelReasons) {
    state.cancelReasons = cancelReasons;
  },
};
