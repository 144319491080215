export { getDarkMode, setDarkMode };

const USER_DARK_MODE = "_standard_darkMode";

/**
 * Retrieves the dark mode from session storage.
 *
 * @returns {boolean} The dark mode preference (true = dark mode, false = white mode)
 */
const getDarkMode = () => {
  return JSON.parse(localStorage.getItem(USER_DARK_MODE) ?? false);
};

/**
 * Sets the dark mode in session storage.
 *
 * @param {boolean} dark_mode
 */
const setDarkMode = (dark_mode) => {
  localStorage.setItem(USER_DARK_MODE, dark_mode);
};
