<template>
  <div class="topbar">
    <h2>
      {{ $t("topbar.topbar_text") }}
      <a href="/pricing">{{ $t("topbar.topbar_link") }}</a>
    </h2>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
.topbar {
  display: flex;
  padding: 0.5rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  align-self: stretch;
  background: linear-gradient(90deg, #5d38ea 0%, #a047dd 100%);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  min-height: 46px;

  h2,
  a {
    color: #fff !important;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 150%; /* 1.6875rem */
    letter-spacing: 0.03125rem;
    margin: 0;
  }

  a {
    text-decoration: underline;
  }
}
</style>
