<template>
  <div>
    <h2 class="d-flex align-items-center justify-content-center">
      <div class="login-modal-text-box">
        <span>{{ $t("pricing.plans.freemium.welcome_modal.title",{emoji:"&#127881"}) }}</span>
      </div>
    </h2>
    <p class="wc-per">
      {{ $t("pricing.plans.freemium.welcome_modal.description") }}
    </p>
    <ul>
      <li v-for="(feature, index) in features" :key="index">
        <span v-html="feature.emoji"></span>
        {{ $t(`pricing.plans.freemium.card.${feature.title}`) }}
      </li>
    </ul>
    <p class="wc-per read_more" v-html="readMoreHtml">
    </p>
  </div>
</template>
<script>
import plans from "@/jsonFiles/plans.json";
import { Dialog } from "element-ui";
export default {
  data() {
    return {};
  },
  components: {
    [Dialog.name]: Dialog,
  },
  computed: {
    features() {
      return plans["freemium"]?.welcome_modal_features || "";
    },

    readMoreHtml() {
      const emoji = '&#128218;';
      const languageUrl = this.$t('pricing.plans.freemium.welcome_modal.language_url');      
      const url = this.$t('pricing.plans.freemium.welcome_modal.url', {language_url:languageUrl});
      return this.$t('pricing.plans.freemium.welcome_modal.read_more', { emoji, url });
    },
  },
};
</script>

<style>
.freemium_modal {
  .el-dialog {
    display: flex;
    padding: 2.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    border-radius: 1rem;
    .el-dialog__header {
      display: none;
    }
  }

  h2 {
    span {
      color: var(--blu-004583, #004583);
      text-align: center;
      font-family: Lato;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 0.9375rem; /* 62.5% */
    }
  }
  .el-dialog__body {
    p {
      color: #004583 !important;
      font-size: 0.875rem !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: 150%;
      text-align: left;
      word-wrap: break-word;
      word-break: break-word;
    }

    .read_more {
      font-weight: bold !important;

      a {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }

  ul {
    padding: 0;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
    min-height: unset !important;
    p {
      margin-bottom: 1rem;
    }
    li {
      color: #004583;
      list-style: none;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 0.44rem;

      i {
        color: #1bb27a;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 900;
        line-height: 1rem; /* 123.077% */
      }
    }
  }
}
</style>
