import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
    namespaced:true,
    state(){
        return {
          showOtpModal: false,
          otpValue: null,
          isOtpValid: false,
          userIsRegisteredOnAws: false,
          userIsLoggedInAws: false,
          AwsCognitoService: null,
          awsUserData: {},
          jwtData: {},
          isPasswordReset: false,
          socialLoginDriver: null
        };
    },
    actions,
    mutations,
    getters
}
