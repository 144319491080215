import axios from "@/axios";

import { apiHandler } from "@/util/errorHandling";

export default {
    /**
     * Fetch brand voices data from the server and update Vuex store.
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} context.rootGetters - Vuex root getters object.
     * @param {Object} context.commit - Vuex commit function.
     */
    async fetchKnowledgeBases({ getters, commit, rootGetters }, workspace_id) {
      
        const user = rootGetters.getUser;
        
        const params = {
          user_id: user.id,
          workspace_id: workspace_id ?? null
        };
        
  
        /**@todo use proper endpoint */
        const response = await axios.get("features/knowledge-base/show", {
          params: params,
        });

        /* const availableKnowledgeBasesNum =
          response.data.brand_voices_limit.available_brand_voices; */
          const availableKnowledgeBasesNum =
          response.data.knowledge_bases_limit.available_knowledge_bases;
          /* const maxKnowledgeBasesNum =
          response.data.brand_voices_limit.max_brand_voices; */
          const maxKnowledgeBasesNum =
          response.data.knowledge_bases_limit.max_knowledge_bases;
          
          //const knowledgeBases = response.data.brand_voices_list;
          const knowledgeBases = response.data.knowledge_bases_list;
        
          //let knowledgeBasesPreset = response.data.knowledge_bases_preset;
          //let knowledgeBasesPreset = response.data.brand_voices_preset;
        
       /*  for (let i = 0; i < knowledgeBasesPreset.length; i++) {
          knowledgeBasesPreset[i].name = `tov.${knowledgeBasesPreset[i].name}` 
          
        } */
        //const defaultKnowledgeBase = knowledgeBases.find((el) => el.default);
        const activeKnowledgeBases = knowledgeBases.filter((el) => el.active);
        console.log(response);
        knowledgeBases.sort((a, b) => a.name.localeCompare(b.name));
  
        // Update Vuex store with the retrieved data
        commit("setAvailableKnowledgeBasesNum", availableKnowledgeBasesNum);
        commit("setMaxKnowledgeBasesNum", maxKnowledgeBasesNum);
        commit("setKnowledgeBases", knowledgeBases);
        //commit("setKnowledgeBasesPreset", knowledgeBasesPreset);
        commit("setActiveKnowledgeBases", activeKnowledgeBases);
        /* if (defaultKnowledgeBase) {
          commit("setDefaultKnowledgeBase", defaultKnowledgeBase);
        } */
        //commit("knowledgebase/setDefaultKnowledgeBase", defaultKnowledgeBase);
      },
  
      /**
       *
       * @async
       * @param {Object} context - The Vuex action context.
       * @param {Object} context.getters - Vuex getters object.
       * @param {Object} context.dispatch - Vuex dispatch function.
       * @param {Object} params - Parameters for creating the user seat.
       * @param {string} params.text - The text to be analyzed.
       */
      async analizeText({ getters, commit, rootGetters }, params) {
        const user = rootGetters.getUser;
        if (params instanceof FormData) {
          params.append("user_id", user.id);
        } else {
          params.user_id = user.id;
        }
        /**@todo use proper endpoint */
        const response = await axios.post("features/knowledge-base/create", params);
  
          // Update Vuex store with the retrieved data
          //const analizedText = response.data.response;
          const analizedText = response.data.response.knowledge_base;
          
          commit("setAnalizedText", analizedText);
          return response
        
        
      },
  
      /**
       * Create a new user seat and update Vuex store.
       *
       * @async
       * @param {Object} context.getters - Vuex getters object.
       * @param {Object} context.commit - Vuex commit object.
       * @param {Object} context.dispatch - Vuex dispatch function.
       * @param {Object} params - The parameters for creating the brand voice.
       * @param {string} params.name - The name of the brand voice.
       * @param {string} params.guidelines - The guidelines for the brand voice.
       * @param {boolean} params.default - Indicates if the brand voice is the default.
       */
      async createKnowledgeBase({ getters, commit, dispatch,rootGetters }, params) {
        return await apiHandler(async () => {
          const user = rootGetters.getUser;
          params.user_id = user.id;
          params.workspace_id = rootGetters.selectedWorkspace?.id
          console.log('.................')
          let response = await axios.put("features/knowledge-base/create", params);
  
          // Refresh Vuex store with the updated data
          await dispatch("fetchKnowledgeBases", rootGetters.selectedWorkspace?.id);
          commit("setAnalizedText", "");
          return response;
        });
  
        // Reset analizedText state
  
      },
  
      /**
       * Update an existing user seat and update Vuex store.
       *
       * @async
       * @param {Object} context.getters - Vuex getters object.
       * @param {Object} context.dispatch - Vuex dispatch function.
       * @param {Object} params - The parameters for creating the brand voice.
       * @param {number} params.id - The ID of the brand voice.
       * @param {string} params.name - The name of the brand voice.
       * @param {string} params.guidelines - The guidelines for the brand voice.
       * @param {boolean} params.default - Indicates if the brand voice is the default.
       */
      async updateKnowledgeBase({ getters, dispatch,rootGetters }, params) {
        await apiHandler(async () => {
          
          const user = rootGetters.getUser;
          params.user_id = user.id;
          if(!params.result){
            params.result = params.rules
          } else if(!params.rules){
            params.rules = params.result
          }
          
          const response = await axios.post("features/knowledge-base/edit", params);
  
          // Refresh Vuex store with the updated data
          await dispatch("fetchKnowledgeBases");
  
          
          return response;
        });
      },
  
      /**
       * 
       * @todo check if still needed
       * Reset the default brand voice and update Vuex store.
       *
       * @param {Object} context.getters - Vuex getters object.
       * @param {Object} context.dispatch - Vuex dispatch function.
       */
      async resetDefault({ getters, dispatch,rootGetters }, workspace_id) {
        await apiHandler(async () => {
          const user = rootGetters.getUser;
          const params = {
            user_id: user.id,
            workspace_id: workspace_id ?? null
          };
          /**@todo use proper endpoint */
          const response = await axios.post(
            "features/knowledge-base/reset_default",
            params
          );
  
          // Refresh Vuex store with the updated data
          await dispatch("fetchKnowledgeBases", workspace_id);
  
          return response;
        });
      },
  
      /**
       * Delete a brand voice and update Vuex store.
       *
       * @param {Object} context.getters - Vuex getters object.
       * @param {Object} context.dispatch - Vuex dispatch function.
       * @param {number} id - The ID of the brand voice entry to be deleted.
       */
      async deleteKnowledgeBase({ getters, dispatch,rootGetters }, id) {
        
        await apiHandler(async () => {
          const user = rootGetters.getUser;
          const params = {
            user_id: user.id,
            id,
          };
          /**@todo use proper endpoint */
          const response = await axios.delete("features/knowledge-base/delete", {
            params: params,
          });
  
          // Refresh Vuex store with the updated data
          await dispatch("fetchKnowledgeBases");
          
          return response;
        });
        console.log('end')
      },
}