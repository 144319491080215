import axios from "axios";
import config from "./config";
import store from '@/store/store';

const instance = axios.create({
  baseURL: config.baseURL
});

const backendLanguage = store.getters.getSelectedLanguage === 'br' ? 'pt-BR' : store.getters.getSelectedLanguage;

instance.interceptors.request.use(async function(config) {
  const selectedLanguage = store.getters.getSelectedLanguage;
  const backendLanguage = selectedLanguage === 'br' ? 'pt-BR' : selectedLanguage;

  let bearerToken = store.getters.getToken ?? null;
  config.headers.Authorization = (bearerToken) ? `Bearer ` + bearerToken : '';
  if (process.env.NODE_ENV === 'production' && !store.getters.getAwsImpersonateToken) {
    if (store.getters['auth/awsCognitoService'] === null) {
      store.commit('auth/setAwsCognitoService');
    }
    const awsCognitoService = store.getters['auth/awsCognitoService'];
    const isTokenExpired = await awsCognitoService.isTokenExpired();
    if (bearerToken && isTokenExpired) {
      await awsCognitoService.refreshExpiredToken();
    }
    const awsJWT = bearerToken ? await awsCognitoService.getIdToken() : null;
    config.headers['X-AWS-Cognito-Token'] = store.getters.getAwsImpersonateToken ? store.getters.getAwsImpersonateToken : awsJWT?.idToken;
  }

  if (config.data) {
    if (config.data instanceof FormData) {
      config.data.append('source', 'saas');
      config.data.append('user_language', backendLanguage);
    } else {
      config.data.source = "saas";
      config.data.user_language = backendLanguage;
    }
  }

  if (config.params) {
    config.params.source = "saas";
    config.params.user_language = backendLanguage;
  }

  return config;
});


export default instance;
