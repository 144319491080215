export default{
    clearModuleBrandInfos(state) {
        Object.assign(state, getDefaultState());
    },
    setAvailableBrandInfosNum(state, availableBrandInfosNum) {
        state.availableBrandInfosNum = availableBrandInfosNum;
    },
    setMaxBrandInfosNum(state, maxBrandInfosNum) {
        state.maxBrandInfosNum = maxBrandInfosNum;
    },
    setBrandInfos(state, brandInfos) {
        state.brandInfos = brandInfos.map((kb) => ({
            ...kb,
            active: Boolean(kb.active),
        })
    );
    },
    
    setActiveBrandInfos(state, activeBrandInfos) {
        state.activeBrandInfos = activeBrandInfos;
    },
    setAnalizedText(state, analizedText) {
        state.analizedText = analizedText;
    },
}