import axios from "@/axios";

import { apiHandler } from "@/util/errorHandling";

export default {
    /**
     * Fetch brand voices data from the server and update Vuex store.
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} context.rootGetters - Vuex root getters object.
     * @param {Object} context.commit - Vuex commit function.
     */
    async fetchBrandInfos({ getters, commit, rootGetters }, workspace_id) {
      
        const user = rootGetters.getUser;
        
        const params = {
          user_id: user.id,
          workspace_id: workspace_id ?? null
        };
        
  
        const response = await axios.get("features/brand-info/show", {
          params: params,
        });

        const availableBrandInfosNum =
          response.data.brand_infos_limit.available_brand_infos;
        const maxBrandInfosNum =
          response.data.brand_infos_limit.max_brand_infos;
          
        const brandInfos = response.data.brand_infos_list;
        console.log('brandInfos',brandInfos)
        const activeBrandInfos = brandInfos.filter((el) => el.active);
        activeBrandInfos.sort((a, b) => a.name.localeCompare(b.name));
  
        // Update Vuex store with the retrieved data
        commit("setAvailableBrandInfosNum", availableBrandInfosNum);
        commit("setMaxBrandInfosNum", maxBrandInfosNum);
        commit("setBrandInfos", brandInfos);
        //commit("setBrandInfosPreset", brandInfosPreset);
        commit("setActiveBrandInfos", activeBrandInfos);
      },
  
      /**
       *
       * @async
       * @param {Object} context - The Vuex action context.
       * @param {Object} context.getters - Vuex getters object.
       * @param {Object} context.dispatch - Vuex dispatch function.
       * @param {Object} params - Parameters for creating the user seat.
       * @param {string} params.text - The text to be analyzed.
       */
      async analizeText({ getters, commit, rootGetters }, params) {
        const user = rootGetters.getUser;
        if (params instanceof FormData) {
          params.append("user_id", user.id);
        } else {
          params.user_id = user.id;
        }
        await apiHandler(async () => {
          const response = await axios.post("features/brand-info/create", params);
  
          // Update Vuex store with the retrieved data
          //const analizedText = response.data.response;
          const analizedText = response.data.response.brand_info;
          
          commit("setAnalizedText", analizedText);
          return response
        });
        
      },
  
      /**
       * Create a new user seat and update Vuex store.
       *
       * @async
       * @param {Object} context.getters - Vuex getters object.
       * @param {Object} context.commit - Vuex commit object.
       * @param {Object} context.dispatch - Vuex dispatch function.
       * @param {Object} params - The parameters for creating the brand voice.
       * @param {string} params.name - The name of the brand voice.
       * @param {string} params.text - The text for the Knowledge base.
       * @param {boolean} params.default - Indicates if the brand voice is the default.
       */
      async createBrandInfo({ getters, commit, dispatch,rootGetters }, params) {
        return await apiHandler(async () => {
          const user = rootGetters.getUser;
          params.user_id = user.id;
          params.workspace_id = rootGetters.selectedWorkspace?.id
          let response = await axios.put("features/brand-info/create", params);
          
          // Refresh Vuex store with the updated data
          await dispatch("fetchBrandInfos", rootGetters.selectedWorkspace?.id);
          commit("setAnalizedText", "");
          return response;
        });
  
        // Reset analizedText state
  
      },
  
      /**
       * Update an existing user seat and update Vuex store.
       *
       * @async
       * @param {Object} context.getters - Vuex getters object.
       * @param {Object} context.dispatch - Vuex dispatch function.
       * @param {Object} params - The parameters for creating the brand voice.
       * @param {number} params.id - The ID of the brand voice.
       * @param {string} params.name - The name of the brand voice.
       * @param {string} params.guidelines - The guidelines for the brand voice.
       * @param {boolean} params.default - Indicates if the brand voice is the default.
       */
      async updateBrandInfo({ getters, dispatch,rootGetters }, params) {
        await apiHandler(async () => {
          
          const user = rootGetters.getUser;
          params.user_id = user.id;
          if(!params.result){
            params.result = params.rules
          } else if(!params.rules){
            params.rules = params.result
          }
          
          const response = await axios.post("features/brand-info/edit", params);
  
          // Refresh Vuex store with the updated data
          await dispatch("fetchBrandInfos");
  
          
          return response;
        });
      },
  
      /**
       * 
       * @todo check if still needed
       * Reset the default brand voice and update Vuex store.
       *
       * @param {Object} context.getters - Vuex getters object.
       * @param {Object} context.dispatch - Vuex dispatch function.
       */
      async resetDefault({ getters, dispatch,rootGetters }, workspace_id) {
        await apiHandler(async () => {
          const user = rootGetters.getUser;
          const params = {
            user_id: user.id,
            workspace_id: workspace_id ?? null
          };
          /**@todo use proper endpoint */
          const response = await axios.post(
            "features/brand-info/reset_default",
            params
          );
  
          // Refresh Vuex store with the updated data
          await dispatch("fetchBrandInfos", workspace_id);
  
          return response;
        });
      },
  
      /**
       * Delete a brand voice and update Vuex store.
       *
       * @param {Object} context.getters - Vuex getters object.
       * @param {Object} context.dispatch - Vuex dispatch function.
       * @param {number} id - The ID of the brand voice entry to be deleted.
       */
      async deleteBrandInfo({ getters, dispatch,rootGetters }, id) {
        
        await apiHandler(async () => {
          const user = rootGetters.getUser;
          const params = {
            user_id: user.id,
            id,
          };
          /**@todo use proper endpoint */
          const response = await axios.delete("features/brand-info/delete", {
            params: params,
          });
  
          // Refresh Vuex store with the updated data
          await dispatch("fetchBrandInfos");
          
          return response;
        });
        console.log('end')
      },
}