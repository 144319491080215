import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

const getDefaultState = () => {
    return {
      availableBrandInfosNum: 0,
      maxBrandInfosNum: 0,
      brandInfos: [],
      //brandInfosPreset: [],
      //defaultBrandInfo: null,
      activeBrandInfos: [],
      analizedText: "",
    };
  };

export default {
    namespaced:true,
    state: getDefaultState(),
    actions,
    mutations,
    getters
}
