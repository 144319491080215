export default {
    changePlanDate(state){
        return state.changePlanDate;
    },
    billingInfo(state){
        return state.billingInfo;
    },
    hasBillingInfo(state){
        return state.hasBillingInfo;
    },
    discounts(state){
        return state.discounts;
    },
    checkoutPlan(state){
        return state.checkoutPlan;
    },
    couponCode(state){
        return state.couponCode;
    },
    defaultPaymentMethod(state){
        return state.defaultPaymentMethod
    },
    stripeClientSecret(state){
        return state.stripeClientSecret
    },
    getStripeElements(state){
        return state.stripeElements
    },
    getPaymentElement(state){
       return  state.paymentElement
    },
    getPaymentForm(state){
       return  state.paymentForm
    },
    getFormIsValid(state){
       return  state.formIsValid
    },
    getIsPaymentMethodChanged(state){
       return  state.isPaymentMethodChanged
    },
    getGtmUserInfo(state){
        return state.gtmUserInfo;
    },
    getCancelReasons(){
        return state.cancelReasons
    }
}
