export default{
    clearModuleKnowledgeBases(state) {
        Object.assign(state, getDefaultState());
    },
    setAvailableKnowledgeBasesNum(state, availableKnowledgeBasesNum) {
        state.availableKnowledgeBasesNum = availableKnowledgeBasesNum;
    },
    setMaxKnowledgeBasesNum(state, maxKnowledgeBasesNum) {
        state.maxKnowledgeBasesNum = maxKnowledgeBasesNum;
    },
    setKnowledgeBases(state, knowledgeBases) {
        state.knowledgeBases = knowledgeBases.map((kb) => ({
            ...kb,
            active: Boolean(kb.active),
            //default: Boolean(kb.default)
        })
    );
    },
    /* setKnowledgeBasesPreset(state, knowledgeBases) {
        state.knowledgeBasesPreset = knowledgeBases;
    }, */
    /* setDefaultKnowledgeBase(state, defaultKnowledgeBase) {
        state.defaultKnowledgeBase = defaultKnowledgeBase;
    }, */
    setActiveKnowledgeBases(state, activeKnowledgeBases) {
        state.activeKnowledgeBases = activeKnowledgeBases;
    },
    setAnalizedText(state, analizedText) {
        state.analizedText = analizedText;
    },
}