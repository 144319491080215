import preloader from "@/util/preloader";
import { Message } from "element-ui";
export default {
    async registerUserOnAws({commit, getters, dispatch}) {
      preloader.fadeIn();
        try {
            await getters.awsCognitoService.registerUser(getters.getAwsUserData);
            commit('setShowOtpModalShowClose', true);
            commit('setUserIsRegisteredOnAws', true);
            preloader.fadeOut();
        } catch (error) {
          if (error.code === 'UsernameExistsException') {
            await dispatch("checkUserAccount");
          } else {
            preloader.fadeOut();
            Message({
              message: error.message,
              type: "error",
            });
          }
        }
    },

  async confirmOtp({commit, getters}) {
    preloader.fadeIn();
    commit('setShowOtpModalShowClose', false);
    try {
      await getters.awsCognitoService.confirmUserViaOTP(getters.getAwsUserData.email, getters.getOtpValue)
      commit('setOtpIsValidOrNot', true);
    } catch (error) {
      preloader.fadeOut();
      Message({
        message: error.message,
        type: "error",
      });
      setTimeout(() => {
        commit('setShowOtpModalShowClose', true);
      }, 1500);
    }
  },

  async resendOtpCode({getters}) {
    preloader.fadeIn();
    try {
      await getters.awsCognitoService.resendOTP(getters.getAwsUserData.email);
      preloader.fadeOut();
    } catch (error) {
      preloader.fadeOut();
      Message({
        message: error.message,
        type: "error",
      });
    }
  },

  async loginUserOnAws({commit, getters}) {
    preloader.fadeIn();
    try {
      const jwtResponse = await getters.awsCognitoService.login(getters.getAwsUserData.email, getters.getAwsUserData.password);
      commit('setJwtData', jwtResponse);
      commit('setUserIsLoggedInAws', true);
    } catch (error) {
      preloader.fadeOut();
      Message({
        message: error.message,
        type: "error",
      });
    }
  },

  setOtpValue({commit}, value) {
      commit('setOtpValue', value);
  },

  setAwsUserData({commit}, value) {
      commit('setAwsUserData', value);
  },

  async logoutAwsUser({commit, getters}) {
      await getters.awsCognitoService.localPlatformSignOut();
      commit("setToken", null, { root: true });
      commit('setAwsUserData', {});
      commit('setUserIsLoggedInAws', false);
      commit('setUserIsRegisteredOnAws', false);
      commit('setOtpIsValidOrNot', false);
      commit('setOtpValue', null);
      commit('setShowOtpModalShowClose', false);
      commit('setAwsCognitoService', null);
      commit('setJwtData', {});
  },

  async deleteAwsUser({getters}) {
      preloader.fadeIn();
      try {
        await getters.awsCognitoService.deleteAwsUser();
      } catch (error) {
        preloader.fadeOut();
        Message({
          message: error.message,
          type: "error",
        });
      }
  },

  async syncAndLoginUserOnAwsCognito({ getters, dispatch }) {
    preloader.fadeIn();
    try {
      await getters.awsCognitoService.syncUserOnAwsCognito(getters.getAwsUserData.email, getters.getAwsUserData.password);
      await dispatch("loginUserOnAws");
    } catch (error) {
      preloader.fadeOut();
      Message({
        message: error.response.data.message,
        type: "error",
      });
    }
  },

  async startResetPasswordFlow({getters, commit}, email) {
      preloader.fadeIn();
      try {
        await getters.awsCognitoService.syncUserOnAwsCognitoIfResetPWDRequested(email);
        setTimeout(async () => {
          await getters.awsCognitoService.resetPassword(email);
          window.location.replace("/reset-password");
        }, 500)
      } catch (error) {
        preloader.fadeOut();
        Message({
          message: error.message ?? error.response.data.message,
          type: "error",
        });
      }
  },

  async completeResetPassword({getters, commit}, params) {
    preloader.fadeIn();
    try {
      await getters.awsCognitoService.confirmResetPassword(params.email, params.otp, params.password);
      commit("setIsPasswordReset", true);
    } catch (error) {
      preloader.fadeOut();
      Message({
        message: error.message,
        type: "error",
      });
    }
  },

  async changeAwsCognitoPasswordAsAuthenticated({getters, commit}, params){
    preloader.fadeIn();
    try {
      await getters.awsCognitoService.changeAwsCognitoPassword(params.current_password, params.new_password);
    } catch (error) {
      preloader.fadeOut();
      Message({
        message: error.message,
        type: "error",
      });
    }
  },

  async checkUserAccount({getters, commit}) {
    preloader.fadeIn();
    try {
      const response = await getters.awsCognitoService.checkAwsCognitoUserAccountStatus(getters.getAwsUserData.email);
      if (response.data.awsUserStatus === 'UNCONFIRMED' || response.data.awsUserStatus === 'Unconfirmed') {
        commit('setShowOtpModalShowClose', true);
        commit('setUserIsRegisteredOnAws', true);
      } else {
        Message({
          message: 'User already exist and has a verified account! Please go to login page!',
          type: "warning",
        });
      }

      preloader.fadeOut();
    } catch (error) {
      preloader.fadeOut();
      Message({
        message: error,
        type: "Something went wrong! Try again!",
      });
    }
  }
}
