export default {
    getIsOtpValid(state) {
        return state.isOtpValid;
    },
    getOtpValue(state) {
        return state.otpValue;
    },
    getShowOtpModal(state) {
        return state.showOtpModal;
    },
    getUserIsRegisteredOnAws(state) {
      return state.userIsRegisteredOnAws;
    },
    getUserIsLoggedInAws(state) {
      return state.userIsLoggedInAws;
    },
    awsCognitoService(state) {
      return state.AwsCognitoService;
    },
    getAwsUserData(state) {
      return state.awsUserData;
    },
    getJwtData(state) {
      return state.jwtData;
    },
    getIsPasswordReset(state) {
      return state.isPasswordReset;
    },
    getSocialLoginDriver(state) {
      return state.socialLoginDriver;
    }

}
