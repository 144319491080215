import {
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  AuthenticationDetails
} from "amazon-cognito-identity-js";
import AWS from "aws-sdk";
import { apiHandler } from "@/util/errorHandling";
import axios from "@/axios";
import { cookies, setCookie } from "@/util/cookies";

export class AwsCognitoService {
  constructor() {
    this.userPool = new CognitoUserPool({
      UserPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
      ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    });

    AWS.config.region = process.env.VUE_APP_DEFAULT_AWS_REGION;
  }

  async registerUser(authRegisterUserDto) {
      const { name, email, password } = authRegisterUserDto;
      return new Promise((resolve, reject) => {
          this.userPool.signUp(email, password, [
              new CognitoUserAttribute({
                  Name: 'name',
                  Value: name,
              }),
            ], null, (err, result) => {
              if (!result) {
                reject(err);
              } else {
                resolve(result.user);
              }
          },
          );
      });
  }

  async login(email, password) {
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.userPool,
    });

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          const idToken = result.getIdToken().getJwtToken();
          const accessToken = result.getAccessToken().getJwtToken();
          const refreshToken = result.getRefreshToken().getToken();
          const expiresAt = result.getIdToken().getExpiration() * 1000;
          localStorage.setItem('idTokenExpiresAt', expiresAt);
          setCookie(cookies.X_AWS_Cognito_Token, idToken);
          resolve({ idToken, accessToken, refreshToken });
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });
  }

  async localPlatformSignOut() {
    const cognitoUser = this.userPool.getCurrentUser();
    return new Promise((resolve, reject) => {
      if (cognitoUser) {
        localStorage.removeItem('idTokenExpiresAt');
        cognitoUser.signOut();
        resolve();
      } else {
        reject(new Error('Unauthenticated!'));
      }
    });
  }

  async deleteAwsUser() {
    const cognitoUser = this.userPool.getCurrentUser();

    return new Promise((resolve, reject) => {
      if (!cognitoUser) {
        return reject(new Error('Unauthenticated!'));
      }

      cognitoUser.getSession((err, session) => {
        if (err) {
          return reject(err);
        }

        if (session.isValid()) {
          cognitoUser.deleteUser((error, result) => {
            if (error) {
              return reject(error);
            }
            resolve(result);
          });
        } else {
          reject(new Error('Session not valid!'));
        }
      });
    });
  }

  async confirmUserViaOTP(email, otp) {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.userPool,
    });

    return new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(otp, true, (err, result) => {
        return err ? reject(err) : resolve(result);
      });
    });
  }

  async resendOTP(email) {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.userPool,
    });

    return new Promise((resolve, reject) => {
      cognitoUser.resendConfirmationCode((err, result) => {
        return err ? reject(err) : resolve(result);
      });
    });
  }

  async getIdToken() {
    const cognitoUser = this.userPool.getCurrentUser();
    if (!cognitoUser) {
      throw new Error('Unauthenticated!');
    }

    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err)
        }

        if (session.isValid()) {
          const idToken =  session.getIdToken().getJwtToken();
          resolve({idToken})
        } else {
          reject(new Error('Session not valid'));
        }
      });
    })
  }

  async refreshExpiredToken() {
    const cognitoUser = this.userPool.getCurrentUser();

    return new Promise((resolve, reject) => {
      if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            return reject(err);
          }

          cognitoUser.refreshSession(session.getRefreshToken(), (err, newSession) => {
            if (err) {
              return reject(err);
            }

            const idToken = newSession.getIdToken().getJwtToken();
            const accessToken = newSession.getAccessToken().getJwtToken();
            const expiresAt = newSession.getIdToken().getExpiration() * 1000;

            localStorage.setItem('idTokenExpiresAt', expiresAt);

            resolve({ idToken, accessToken });
          });
        });
      } else {
        reject(new Error('Unauthenticated!'));
      }
    });
  }

  isTokenExpired() {
    const expiresAt = localStorage.getItem('idTokenExpiresAt');
    return !expiresAt || Date.now() > parseInt(expiresAt);
  }

  async syncUserOnAwsCognito(email, password) {
    await apiHandler(async () => {
      return await axios.post("services/auth/sync-user-to-aws-cognito", {
        email: email,
        password: password
      });
    });
  }

  async resetPassword(username) {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: this.userPool,
      });

      cognitoUser.forgotPassword({
        onSuccess: (data) => {
          console.log('Codice inviato con successo:', data);
          resolve(data);
        },
        onFailure: (err) => {
          console.error('Errore durante il reset della password:', err);
          reject(err);
        },
      });
    });
  }

  async confirmResetPassword(username, verificationCode, newPassword) {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: this.userPool,
      });

      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: () => {
          console.log('Password aggiornata con successo.');
          resolve();
        },
        onFailure: (err) => {
          console.error('Errore durante la conferma della password:', err);
          reject(err);
        },
      });
    });
  }

  async changeAwsCognitoPassword(oldPassword, newPassword) {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.userPool.getCurrentUser();

      if (!cognitoUser) {
        return reject(new Error('Unauthenticated!'));
      }

      cognitoUser.getSession((err, session) => {
        if (err || !session.isValid()) {
          return reject(new Error('Session not valid!'));
        }

        cognitoUser.changePassword(oldPassword, newPassword, (error, result) => {
          if (error) {
            console.error('error changing password:', error);
            reject(error);
          } else {
            console.log('Password change complete:', result);
            resolve('Password change complete!');
          }
        });
      });
    });
  }

  async syncUserOnAwsCognitoIfResetPWDRequested(email) {
    await apiHandler(async () => {
      return await axios.post("services/auth/sync-user-to-aws-cognito-reset-pwd-request", {
        email: email,
      });
    });
  }
  async checkAwsCognitoUserAccountStatus(email) {
    return await axios.post("services/auth/check-aws-cognito-user-account-status", {
      email: email,
    });
  }
}
