export default {
    getAvailableBrandInfosNum(state) {
        return state.availableBrandInfosNum;
      },
    getMaxBrandInfosNum(state) {
        return state.maxBrandInfosNum;
    },
    getBrandInfos(state) {
        return state.brandInfos;
    },
    /* getDefaultBrandInfo(state) {
    return state.defaultBrandInfo;
    }, */
    getActiveBrandInfos(state) {
        return [...state.activeBrandInfos/* , ...state.brandInfosPreset */];
    },
    getAnalizedText(state) {
        return state.analizedText;
    },
    canCreateBrandInfo(state, getters, rootGetters) {
        const role =rootGetters.user.user.role
        return (
            role != 'user' &&
            getters.getMaxBrandInfosNum > 0 &&
            getters.getAvailableBrandInfosNum > 0
        );
    },
}