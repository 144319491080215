<template>
  <card>
    <div class="d-flex align-items-center justify-content-between brand_voice_panel">
      <div>
        <h4 slot="header" class="title mb-0">
          {{ $t("pricing.downgrade_modal.brand_infos") }}
        </h4>
        <p slot="header" class="subtitle mb-0">{{ $t("workspace.settings.biDesc") }}</p>
      </div>
    </div>
    <BrandInfoTable />
  </card>
</template>
<script>
import BrandInfoTable from "./BrandInfoTable.vue";
import { Dialog } from "element-ui";

export default {
  components: {
    BrandInfoTable,
    [Dialog.name]: Dialog,
  },
  props: {
    name: {
      type: String,
    },
    description: {
      type: String,
    },
    label: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formComponentKey: 0,
    };
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
