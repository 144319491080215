var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('BreadCrumb',[_c('BreadCrumbItem',{staticStyle:{"display":"inline-block"}},[_c('router-link',{staticClass:"breadcrumb-link",attrs:{"to":"/dashboard"}},[_vm._v(" "+_vm._s(_vm.$t("sidebar.dashboard"))+" ")])],1),_c('BreadCrumbItem',{staticStyle:{"display":"inline-block"}},[_c('span',{staticClass:"breadcrumb-current"},[_vm._v(_vm._s(_vm.$t("workspace.settings.title")))])])],1),_c('div',{staticClass:"col-12"},[_c('h1',[_vm._v(_vm._s(_vm.$t("workspace.settings.title")))])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"button_switch_panel-container"},[(!_vm.isPersonal)?_c('button',{staticClass:"button_switch_panel",class:_vm.switchTab == 'member-settings' ? 'button_switch_panel--selected' : '',on:{"click":function($event){_vm.switchTab = 'member-settings'}}},[_vm._v(" "+_vm._s(_vm.$t("workspace.memberSettings"))+" ")]):_vm._e(),_c('button',{staticClass:"button_switch_panel",class:_vm.switchTab == 'bv-settings' ||
          (_vm.isPersonal &&
            _vm.switchTab !== 'website-settings' &&
            _vm.switchTab !== 'bi-settings')
            ? 'button_switch_panel--selected'
            : '',on:{"click":function($event){_vm.switchTab = 'bv-settings'}}},[_vm._v(" "+_vm._s(_vm.$t("pricing.downgrade_modal.brand_voices"))+" ")]),_c('button',{staticClass:"button_switch_panel",class:_vm.switchTab == 'bi-settings' ||
          (_vm.isPersonal &&
            _vm.switchTab !== 'website-settings' &&
            _vm.switchTab !== 'bv-settings')
            ? 'button_switch_panel--selected'
            : '',on:{"click":function($event){_vm.switchTab = 'bi-settings'}}},[_vm._v(" "+_vm._s(_vm.$t("pricing.downgrade_modal.brand_infos"))+" ")]),_c('button',{staticClass:"button_switch_panel",class:_vm.switchTab == 'website-settings' ? 'button_switch_panel--selected' : '',on:{"click":function($event){_vm.switchTab = 'website-settings'}}},[_vm._v(" "+_vm._s(_vm.$t("workspace.settings.website"))+" ")])])]),_c('transition',{attrs:{"name":"tab-fade"}},[(_vm.switchTab == 'member-settings' && !_vm.isPersonal)?_c('div',{staticClass:"col-12"},[_c('WorkspaceMemberSettings')],1):_vm._e()]),_c('transition',{attrs:{"name":"tab-fade"}},[(_vm.switchTab == 'bv-settings')?_c('div',{staticClass:"col-12"},[_c('BrandVoice')],1):_vm._e()]),_c('transition',{attrs:{"name":"tab-fade"}},[(_vm.switchTab == 'bi-settings')?_c('div',{staticClass:"col-12"},[_c('BrandInfo')],1):_vm._e()]),_c('transition',{attrs:{"name":"tab-fade"}},[(_vm.switchTab == 'website-settings')?_c('div',{staticClass:"col-12"},[_c('WebsiteSettings')],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }