import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

const getDefaultState = () => {
    return {
      availableKnowledgeBasesNum: 0,
      maxKnowledgeBasesNum: 0,
      knowledgeBases: [],
      //knowledgeBasesPreset: [],
      //defaultKnowledgeBase: null,
      activeKnowledgeBases: [],
      analizedText: "",
    };
  };

export default {
    namespaced:true,
    state: getDefaultState(),
    actions,
    mutations,
    getters
}
