<template>
  <div class="row mt-3 mb-5">
    <div class="col-12">
      <div class="table-responsive table-text-to-image">
        <el-table :data="brandInfos" v-loading="loading" style="width: 100%">
          <!-- Name -->
          <el-table-column
            min-width="200"
            prop="Voice Name"
            :label="$t('brandInfos.voiceName')"
            sortable
          >
            <template #default="scope">
              {{ scope.row.name }}
            </template>
          </el-table-column>

          <!-- Type -->
          <el-table-column
            min-width="200"
            prop="Voice Type"
            :label="$t('resultsTable.type')"
            align="center"
            sortable
          >
            <template #default="scope">
              {{ $t("feature.brand_info.custom_brand_info") }}
            </template>
          </el-table-column>

          <!-- Created At -->
          <el-table-column
            min-width="200"
            prop="Created on"
            :label="$t('resultsTable.createdOn')"
            align="center"
            sortable
          >
            <template #default="scope">
              {{ formatDate(scope.row.created_at) }}
            </template>
          </el-table-column>

          <!-- On/Off -->
          <el-table-column :min-width="135" align="right" :label="'On/Off'" sortable>
            <template #default="scope">
              <el-switch
                v-model="scope.row.active"
                @change="toggleBrandInfo(scope.row.id)"
              ></el-switch>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, Switch } from "element-ui";
import { formatDate } from "@/util/date";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Switch.name]: Switch,
  },
  data() {
    return {
      KbFormVisible: false,
      loading: true,
    };
  },
  computed: {
    brandInfos() {
      return this.$store.getters["brandinfo/getBrandInfos"];
    },
    workspaceId() {
      return this.$route.params?.id ?? this.$store.getters.selectedWorkspace.id;
    },
  },
  methods: {
    formatDate,

    async setAsDefault(brandInfo) {
      this.loading = true;
      await this.$store
        .dispatch("brandinfo/updateBrandInfo", {
          ...brandInfo,
          default: true,
        })
        .then(() => (this.loading = false));
    },

    toggleBrandInfo(brandInfoId) {
      console.log(brandInfoId);
      this.$store.dispatch("toggleBrandInfo", {
        id: this.workspaceId,
        brand_info_id: brandInfoId,
      });
    },
  },
  mounted() {
    this.$store
      .dispatch("brandinfo/fetchBrandInfos", this.workspaceId)
      .then(() => (this.loading = false));
  },
};
</script>

<style>
.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}

.el-switch .el-switch__core {
  border-color: transparent;
  background: #dcdfe6;
  width: 44px !important;
  transition: all 0.3s;
}

.el-switch.is-checked .el-switch__core {
  border-color: transparent;
  background: linear-gradient(107deg, #034a98 6.12%, #0e173f 44.98%, #321979 85.83%);
}
</style>
