var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.walletInfoObj?.activePlanPackage?.plan_type === 'basic' ||
    _vm.walletInfoObj?.freemium_config
  )?_c('div',{staticClass:"wallet"},[(_vm.showCreditsPanel)?_c('div',{staticClass:"wallet-info"},[_c('div',{staticClass:"wallet-info-credit w-100",style:(_vm.walletInfoObj.freemium_config ? 'cursor:pointer' : ''),on:{"click":function($event){_vm.walletInfoObj.freemium_config ? _vm.showFreemiumInfoModal() : ''}}},[_c('span',{staticClass:"wallet-title"},[_vm._v(_vm._s(!_vm.walletInfoObj.freemium_config ? "Credits" : _vm.$t("pricing.plans.freemium.counter_title")))]),(!_vm.walletInfoObj.freemium_config)?_c('SidebarWalletCell',{attrs:{"title":'AI',"credits":_vm.walletInfoObj?.activePlanPackage?.plan_type === 'basic'
            ? _vm.walletInfoObj?.aiCredits
            : 'Unlimited',"paymentUrl":_vm.walletInfoObj?.isAiCreditsUnlimited ? '' : this.paymentUrl}}):_vm._e(),(!_vm.isDemoUser && !_vm.walletInfoObj.freemium_config)?_c('div',{staticClass:"divider"}):_vm._e(),(
          _vm.walletInfoObj?.extraCredits > 0 && !_vm.walletInfoObj.freemium_config
        )?_c('SidebarWalletCell',{attrs:{"title":'Extra',"credits":_vm.walletInfoObj?.extraCredits,"paymentUrl":_vm.walletInfoObj?.isAiCreditsUnlimited ? '' : this.paymentUrl}}):_vm._e(),(_vm.walletInfoObj?.freemium_config)?_c('SidebarWalletCell',{attrs:{"freemium":_vm.walletInfoObj?.freemium_config ? true : false,"credits":_vm.freemiumCredits}}):_vm._e(),(
          _vm.walletInfoObj?.extraCredits > 0 || !_vm.walletInfoObj?.freemium_config
        )?_c('div',{staticClass:"divider"}):_vm._e()],1),(_vm.showUpgradeButton)?_c('SidebarUpgradeButton',{attrs:{"paymentUrl":this.paymentUrl}}):_vm._e()],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }